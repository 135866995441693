import React from "react";
import Script from "next/script";

const ChatBubble = () => {
  return (
    <div id="pooi">
      <Script
        id="hs-script-loader"
        src="//js-eu1.hs-scripts.com/19647191.js"
        strategy={"lazyOnload"}
      />
    </div>
  );
};
export default ChatBubble;
